<template>
  <vx-card no-shadow>
    <vue-element-loading
      :active="showCardLoading && !preventAppLoading"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <slot name="title">
      <h2 class="mb-5 hidden md:block">
        {{ $t('$UserSettings.$Account.$Details.TabTitle') }}
      </h2>
    </slot>

    <form @submit.prevent>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            v-model="model.email"
            class="w-full required"
            :label="$tc('$General.Email')"
            :name="$tc('$General.Email')"
            type="email"
            v-validate="'required|email'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            :danger="errors.has($tc('$General.Email'))"
            :danger-text="errors.first($tc('$General.Email'))"
            val-icon-danger="clear"/>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-6 md:mt-0">
          <vs-input
            v-model="model.phone"
            class="w-full required"
            :label="$tc('$General.PhoneNumber')"
            :name="$tc('$General.PhoneNumber')"
            v-validate="'required|max:50'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-phone"
            icon-pack="feather"
            :danger="errors.has($tc('$General.PhoneNumber'))"
            :danger-text="errors.first($tc('$General.PhoneNumber'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            v-model="model.name"
            class="w-full"
            :label="$tc('$General.BusinessName')"
            :name="$tc('$General.BusinessName')"
            v-validate="'max:200'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            :danger="errors.has($tc('$General.BusinessName'))"
            :danger-text="errors.first($tc('$General.BusinessName'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full md:w-1/2 input-country-edit">
          <vs-input
            v-model="model.country"
            class="w-full required"
            type="hidden"
            :label="$tc('$General.Country')"
            :name="$tc('$General.Country')"
            v-validate="'required|max:200'"
            data-vv-validate-on="blur|input|change"/>
          <v-select
            ref="vSelectCountries"
            class="w-full"
            :class="{'border-danger': errors.has($tc('$General.Country'))}"
            label="name"
            v-model="selectedCountry"
            :multiple="false"
            :closeOnSelect="true"
            :options="countryOptions"
            :clearable="false"
            @input="selectedCountryInput()">
          </v-select>
          <div
            v-show="errors.has($tc('$General.Country'))"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;">
                <span class="span-text-validation">
                {{ errors.first($tc('$General.Country')) }}
                </span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-6 md:mt-0">
          <vs-input
            v-model="model.state"
            class="w-full required"
            :label="$tc('$General.State')"
            :name="$tc('$General.State')"
            v-validate="'required|max:200'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-map"
            icon-pack="feather"
            :danger="errors.has($tc('$General.State'))"
            :danger-text="errors.first($tc('$General.State'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            v-model="model.city"
            class="w-full required"
            :label="$tc('$General.City')"
            :name="$tc('$General.City')"
            v-validate="'required|max:200'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-map"
            icon-pack="feather"
            :danger="errors.has($tc('$General.City'))"
            :danger-text="errors.first($tc('$General.City'))"
            val-icon-danger="clear"/>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-6 md:mt-0">
          <vs-input
            v-model="model.postCode"
            class="w-full required"
            :label="$t('$General.ZipPostalCode')"
            :name="$t('$General.ZipPostalCode')"
            v-validate="'required|max:10'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-hash"
            icon-pack="feather"
            :danger="errors.has($t('$General.ZipPostalCode'))"
            :danger-text="errors.first($t('$General.ZipPostalCode'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full md:w-1/2">
          <vs-input
            v-model="model.address"
            class="w-full required"
            :label="$t('$General.AddressLine1')"
            :name="$t('$General.AddressLine1')"
            v-validate="'required|max:500'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-map"
            icon-pack="feather"
            :danger="errors.has($t('$General.AddressLine1'))"
            :danger-text="errors.first($t('$General.AddressLine1'))"
            val-icon-danger="clear"/>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-6 md:mt-0">
          <vs-input
            v-model="model.address2"
            class="w-full"
            :label="`${$t('$General.AddressLine2')}`"
            :name="$t('$General.AddressLine2')"
            v-validate="'max:500'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-map"
            icon-pack="feather"
            :danger="errors.has($t('$General.AddressLine2'))"
            :danger-text="errors.first($t('$General.AddressLine2'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div class="vx-col w-full md:w-1/2 input-timezone-edit">
          <vs-input
            :value="model.timeZone"
            class="w-full required"
            type="hidden"
            :label="$tc('$General.TimeZone')"
            :name="$tc('$General.TimeZone')"
            v-validate="'required|max:200'"
            data-vv-validate-on="blur|input"/>
            <timezone-select
              v-model="model.timeZone"
              :border-danger="errors.has($tc('$General.TimeZone'))"
              :clearable="false"
              :fix-z-index="this.onModal"/>
          <div
            v-show="errors.has($tc('$General.TimeZone'))"
            class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
            style="height: 19px;">
                <span class="span-text-validation">
                {{ errors.first($tc('$General.TimeZone')) }}
                </span>
          </div>
        </div>

        <div
          v-if="selectedCountry && selectedCountry.taxInfo"
          class="vx-col w-full md:w-1/2">
          <div class="flex justify-start items-center">
            <label class="vs-input--label">{{ selectedCountry.taxInfo.description }}</label>
            <tax-id-verification
              v-if="taxIdIsValid"
              class="ml-2"
              :status="taxId.verification.status"
              :description="selectedCountry.taxInfo.description"
              :name="taxId.verification.verified_name"
              :address="taxId.verification.verified_address"/>
          </div>

          <vs-input
            v-model="model.taxId.value"
            class="w-full"
            :placeholder="selectedCountry.taxInfo.example"
            :name="selectedCountry.taxInfo.description"
            v-validate="'max:50'"
            data-vv-validate-on="blur|input"
            icon-no-border
            icon="icon icon-hash"
            icon-pack="feather"
            :danger="errors.has(selectedCountry.taxInfo.description)"
            :danger-text="errors.first(selectedCountry.taxInfo.description)"
            val-icon-danger="clear"/>
        </div>
      </div>

      <slot
        name="buttons-wrapper"
        v-bind:save="saveAccountDetails"
        v-bind:cancel="setModel"
        v-bind:formIsChanged="formIsChanged">
        <div class="flex items-center justify-start mt-12">
          <slot
            name="buttons"
            v-bind:save="saveAccountDetails"
            v-bind:cancel="setModel"
            v-bind:formIsChanged="formIsChanged">
            <vs-button
              @click="saveAccountDetails()">
              {{ $t('$General.Update') }}
            </vs-button>

            <vs-button
              v-show="formIsChanged"
              class="ml-3 vs-button-cancel-flat"
              color="danger"
              @click="setModel()">
              {{ $t('$General.Cancel') }}
            </vs-button>
          </slot>

        </div>
      </slot>
    </form>
  </vx-card>
</template>

<script>
import { mapActions } from 'vuex';
import vSelect from 'vue-select';

// utils
import allCountries from '@/assets/utils/all-countries';

// components
import UserAccountBillingInformationTaxIdVerification
from '@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetailsTaxIdVerification.vue';
import TimezoneSelect from '@/views/modules/_components/TimezoneSelect.vue';

// services
import tenantService from '@/api/modules/tenant.service';

/**
 * Component for allow the user view and edit his account details information
 *
 * @module views/modules/user-account/UserAccountSettingsDetails
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [onModal=false] - indicate if teh component is inside modal
 * @vue-data {Object} [payload=null] - payload data
 * @vue-data {Object} [model={...}] - model data
 * @vue-data {Object | null} [taxId=null] - tax id information
 * @vue-data {Object} [selectedCountry=null] - selected country
 * @vue-data {Object[]} [countryOptions=[...]] - country options
 * @vue-data {boolean} [showCardLoading=false] - show card loading
 * @vue-computed {boolean} formIsChanged - indicate if the form was changed
 * @vue-computed {boolean} taxIdIsValid - indicate if tax id is valid for show
 * @vue-event {void} fetchData - fetch component data
 * @vue-event {void} selectedCountry - watch to set country in model
 * @vue-event {void} created - hook to fetch billing information
 * @vue-event {void} fetchSettingDetails - fetch billing information
 * @vue-event {void} setModel - set model from payload data
 * @vue-event {void} saveAccountDetails - save model
 * @vue-event {void} selectedCountryInput - called on input selected country
 */
export default {
  name: 'UserAccountSettingsDetails',
  i18n: {
    messages: {
      en: {
        UpdatedSuccessNotifyTitle: 'Updated account details',
        UpdatedSuccessNotifyMsg: 'Account details updated successfully',
      },
    },
  },
  components: {
    vSelect,
    taxIdVerification: UserAccountBillingInformationTaxIdVerification,
    TimezoneSelect,
  },
  props: {
    onModal: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      payload: null,
      model: {
        email: '',
        phone: '',
        name: '',
        country: '',
        state: '',
        city: '',
        postCode: '',
        address: '',
        address2: '',
        timeZone: '',
        taxId: {
          type: '',
          value: '',
        },
      },
      taxId: null,
      selectedCountry: null,
      countryOptions: allCountries,
      showCardLoading: false,
    };
  },
  computed: {
    formIsChanged() {
      return Object.keys(this.validationFields).some((key) => this.validationFields[key].changed);
    },
    taxIdIsValid() {
      return this.taxId && this.taxId.value && this.taxId.type
        && this.taxId.verification && this.taxId.verification.status
        && this.taxId.type === this.selectedCountry.taxInfo.type
        && this.taxId.value === this.model.taxId.value;
    },
  },
  watch: {
    selectedCountry(val) {
      this.model.country = val ? val.iso2 : null;
    },
    'model.taxId.value': function (val) {
      if (val) {
        this.model.taxId.value = val.replace(/ /g, '');
      }
    },
  },
  created() {
    if (!this.onModal) {
      window.history.replaceState({}, null, '/account/details');
    }

    this.fetchData();
  },
  methods: {
    ...mapActions({
      updateTenantAccountDetailsSettings: 'auth/updateTenantAccountDetailsSettings',
    }),
    async fetchData() {
      this.showCardLoading = true;
      await this.fetchSettingDetails();
      this.showCardLoading = false;
    },
    async fetchSettingDetails() {
      const resp = await tenantService.getAccountSettingsDetails();

      this.payload = resp.data.detailsInformation;
      this.taxId = resp.data.taxId;
      this.setModel();
    },
    setModel() {
      this.model.name = this.payload.name || '';
      this.model.email = this.payload.email || '';
      this.model.phone = this.payload.phone || '';
      this.model.country = this.payload.country || '';
      this.model.state = this.payload.state || '';
      this.model.city = this.payload.city || '';
      this.model.postCode = this.payload.postCode || '';
      this.model.address = this.payload.address || '';
      this.model.address2 = this.payload.address2 || '';
      this.model.timeZone = this.payload.timeZone || this.model.timeZone;
      this.model.taxId.type = this.payload.taxId
        ? this.payload.taxId.type || ''
        : '';
      this.model.taxId.value = this.payload.taxId
        ? this.payload.taxId.value || ''
        : '';
      this.selectedCountry = this.countryOptions.find(
        (country) => country.iso2 === this.payload.country,
      ) || null;

      this.$validator.reset();
    },
    async saveAccountDetails() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        document.getElementsByName([this.$validator.errors.items[0].field])[0].focus();
        return;
      }

      this.showCardLoading = true;
      const resp = await this.updateTenantAccountDetailsSettings(this.model);
      this.showCardLoading = false;
      this.payload = resp.detailsInformation;
      this.taxId = resp.taxId;

      this.$showSuccessActionNotification({
        title: this.$t('UpdatedSuccessNotifyTitle'),
        text: this.$t('UpdatedSuccessNotifyMsg'),
      });

      this.setModel();
    },
    selectedCountryInput() {
      this.model.taxId.type = this.selectedCountry && this.selectedCountry.taxInfo
        ? this.selectedCountry.taxInfo.type : '';
      this.model.taxId.value = '';
    },
  },
};
</script>
